import Styled from 'styled-components'

import theme from '../theme'


const HideMobile = Styled.div`
  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`


export default HideMobile

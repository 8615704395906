import Styled from 'styled-components'

import theme from '../theme'


const H2 = Styled.h2`
  font-size: 60px;
  line-height: 65px;
  color: ${(props) => props.white ? theme.color.white : theme.color.alternativeBlack};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 25px; 
    line-height: 30px;
    font-weight: 200;
  }
`


export default H2

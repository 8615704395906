import React, { Component } from 'react'
import Styled from 'styled-components'
import Modal from 'react-modal'
import { connect } from 'react-redux'

import { closeModal, openModalMeditation } from '../actions/app'
import { scrollTop } from '../helper'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ArrowUp from '../components/ArrowUp'
import Meditation from '../components/Meditation'

Modal.setAppElement('#___gatsby')

const Container = Styled.div`
  padding-top: 105px;
`

class Layout extends Component {
  /* LIFECYCLE */

  /**
   * @constructor
   * @param {*} props - Properties of the Component
   */
  constructor(props) {
    super(props)

    this.timeout = null
  }

  /**
   * @componentDidMount
   */
  componentDidMount() {
    scrollTop()
    if (typeof document !== 'undefined') {
      window.setTimeout(
        () => (document.body.parentElement.style.scrollBehavior = 'smooth'),
        1000,
      )
    }
  }

  /**
   * @componentWillUnmount
   */
  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.body.parentElement.style.scrollBehavior = 'unset'
    }
  }

  /* RENDER */

  /**
   * @render
   * @returns {JSX}
   */
  render() {
    const { children, modalOpened, hideFooter } = this.props

    const footer = hideFooter ? '' : <Footer />
    const arrowUp = hideFooter ? '' : <ArrowUp />
    return (
      <>
        <Header onOpenModalMeditation={this.props.openModalMeditation} />
        <Container>{children}</Container>
        {footer}

        <Meditation
          onClose={this.props.closeModal}
          isOpen={modalOpened === 'meditation'}
        />
        {arrowUp}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  modalOpened: state.app.modalOpened,
})

export default connect(mapStateToProps, {
  closeModal,
  openModalMeditation,
})(Layout)

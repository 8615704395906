import Styled from 'styled-components'

import theme from '../theme'


const H1 = Styled.h1`
  font-size: 78px;
  font-weight: bolder;
  font-family: ${theme.typography.gothic};
  color: ${theme.color.primary};

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 50px; 
  }
`


export default H1

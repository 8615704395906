import React, { PureComponent, createRef } from 'react'
import Styled from 'styled-components'
import Modal from 'react-modal'

import theme from '../theme'
import MeditationSrc from '../assets/meditation.mp4'
import PlaySrc from '../assets/play.svg'


const Content = Styled.div`
  width: 100%;
  display: flex;
  height: 580px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.white};
`

const PlayImg = Styled.img`
  width: 130px;
  height: 118px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
`

const Video = Styled.video`
  width: 100%;
  height: 100%;
`


class Meditation extends PureComponent {
  /* LIFECYCLE */

  /**
   * @constructor
   * @param {*} props - Properties of the PureComponent
   */
  constructor(props) {
    super(props)

    this.video = createRef()
    this.state = {
      playing: false,
    }
  }

  /**
   * @componentDidMount
   */
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', this.onKeyDown)
    }
  }

  /**
   * @componentWillUnmount
   */
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('keydown', this.onKeyDown)
    }
  }


  /* METHODS */

  onKeyDown = (e) => {
    if (e.keyCode === 32) {
      this.playVideo()
    }
  }

  playVideo = () => {
    const { playing } = this.state
    const { current: video } = this.video

    if (video) {
      if (playing) {
        video.pause()
      } else {
        video.play()
      }

      this.setState({ playing: !playing })
    }
  }

  onClose = () => {
    const { playing } = this.state
    const { current: video } = this.video

    if (video && playing) {
      video.pause()
    }

    this.setState({ playing: false })
    this.props.onClose()
  }


  /* RENDER */

  /**
   * @render
   * @returns {JSX}
   */
  render() {
    const {
      isOpen,
    } = this.props
    const { playing } = this.state

    return (
      <Modal
        isOpen={isOpen}
        closeTimeoutMS={300}
        onRequestClose={this.onClose}
        style={{
          overlay: {
            zIndex: 200,
            maxWidth: '100vw',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            top: '50%',
            left: '50%',
            padding: 18,
            bottom: 'none',
            borderRadius: 0,
            maxHeight: '90%',
            maxWidth: '90vw',
            overflowY: 'auto',
            width: theme.grid.fullWidth,
            backgroundColor: theme.color.alternativeWhite,
            transform: 'translateX(-50%) translateY(-50%)',
          },
        }}
      >
        <Content onClick={this.playVideo}>
          <Video ref={this.video}>
            <source src={MeditationSrc} type="video/mp4" />
          </Video>
          {!playing && <PlayImg src={PlaySrc} alt="méditation" />}
        </Content>
      </Modal>
    )
  }
}


export default Meditation

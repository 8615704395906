import React from 'react'
import Styled from 'styled-components'

import {
  IOS_LINK,
  GIFT_LINK,
  CONTACT_LINK,
  ANDROID_LINK,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  CONDITIONS_LINK,
  PRIVACY_LINK,
} from '../constant'

import theme from '../theme'
import FooterSrc from '../assets/Footer.png'
import MindIcon from '../assets/icons/Mind.svg'
import CloudSrc from '../assets/Cloud-Footer.png'
import AndroidSrc from '../assets/google-play-fr.png'
import IosSrc from '../assets/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.png'


const CovidText = Styled.p`
  color: white;
  text-align: center;
  margin: 10px 20px 0px 20px;
  font-size: 25px;
  font-weight: lighter;

  @media (max-width: ${theme.breakpoint}px) {
    margin: 10px 20px 0px 20px;
    font-size: 16px;
  }
`

const CovidTextStrong = Styled.p`
color: white;
text-align: center;
margin: 10px 20px 0px 20px;
font-size: 25px;
font-weight: bold;

@media (max-width: ${theme.breakpoint}px) {
  font-size: 16px;
}
`

const CovidLink = Styled.a`
  font-size: 25px;

  @media (max-width: ${theme.breakpoint}px) {
    font-size: 16px;
  }
`

const Container = Styled.footer`
  z-index: 2;
  margin-top: -50px;
  position: relative;
  border-radius: ${theme.radius.big}px ${theme.radius.big}px 0 0;
  background-color: ${theme.color.secondary};
`

const Content = Styled.div`
  margin: 0 auto;
  padding-top: 45px;
  max-width: ${theme.grid.fullWidth}px;

  @media (max-width: ${theme.breakpoint}px) {
    margin: 0;
    padding-top: 1px;
    margin-left: 130px;
    width: calc(100% - 150px);
  }
`

const Separator = Styled.div`
  height: 1px;
  width: 100%;
  margin-top: 90px;
  display: inline-block;
  background-color: #f98762;

  @media (max-width: ${theme.breakpoint}px) {
    margin-top: 20px;
  }
`

const SeparatorDesktop = Styled(Separator)`
  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const Columns = Styled.div`
  display: flex;
  max-width: 900px;
  margin-top: 40px;
  margin-left: auto;
  align-items: flex-start;
  justify-content: space-between;
`

const Column = Styled.div`
  width: 33%;
`

const ColumnDesktop = Styled(Column)`
  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const ColumnTitle = Styled.h4`
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 23px;
  color: ${theme.color.alternativeWhite};
  font-family: ${theme.typography.gascogne};
`

const Link = Styled.a`
  display: block;
  font-size: 14px;
  line-height: 1.49;
  color: ${theme.color.alternativeWhite} !important;
`

const Anchors = Styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  color: ${theme.color.alternativeWhite};

  & > * {
    width: 100%;
    display: block;
    text-align: left;
  }
`

const Mind = Styled(MindIcon)`
  height: 70px;
  position: absolute;
  left: 0;
  top: calc(7.5px - 27.5px);
`

const EndContainer = Styled.div`
  margin-top: 105px;
  position: relative;
  text-align: center;
  font-size: 14px;
  padding-bottom: 115px;
  color: ${theme.color.white};

  & > * {
    display: inline;
  }

  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const FooterImg = Styled.img`
  top: 40px;
  width: 285px;
  z-index: -1;
  position: absolute;

  @media (max-width: ${theme.breakpoint}px) {
    top: -50px;
    left: -50px;
    width: 165px;
  }
`

const CloudImg = Styled.img`
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 500px;
  position: absolute;

  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const DownloadImg = Styled.a`
  width: 214px;
  display: block;
  cursor: pointer;
  margin-bottom: 20px;

  & > * {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${theme.breakpoint}px) {
    width: 100px;
    margin-bottom: 0;
  }
`

const EndContainerDownload = Styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EndContainerText = Styled.div`
  font-size: 14px;
  margin-top: 30px;

  & > * {
    display: inline;
    font-size: 14px;
  }
`

const EndContainerMiddle = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: align-items;
  justify-content: flex-start;

  & > * {
    color: ${theme.color.alternativeWhite};
  }
`

const EndContainerMobile = Styled.div`
  width: 100%;
  display: none;
  margin-top: 35px;
  align-items: center;
  padding-bottom: 50px;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoint}px) {
    display: flex;
  }

  & ${Mind} {
    margin-left: 50px;
    position: static;
  }
`


const Footer = ({
}) => {
  const ios = <DownloadImg target="_blank" href={IOS_LINK}><img src={IosSrc} /></DownloadImg>
  const android = <DownloadImg target="_blank" href={ANDROID_LINK}><img src={AndroidSrc} /></DownloadImg>

  return (
    <Container>
      <FooterImg src={FooterSrc} />
      <CloudImg src={CloudSrc} />
      <Content>
        <SeparatorDesktop />
        <Columns>
          <Column>
            <ColumnTitle>Social</ColumnTitle>
            <Link target="_blank" href={FACEBOOK_LINK}>Facebook</Link>
            <Link target="_blank" href={INSTAGRAM_LINK}>Instagram</Link>
          </Column>
          <Column>
            <ColumnTitle>Découvrir Mind</ColumnTitle>
            <Anchors>
              <Link href={GIFT_LINK} target="_blank">S'abonner</Link>
              <Link href={`mailto:${CONTACT_LINK}`} target="_blank">{CONTACT_LINK}</Link>
            </Anchors>
          </Column>
          <ColumnDesktop>
            <ColumnTitle>Téléchargez</ColumnTitle>
            {ios}
            {android}
          </ColumnDesktop>
        </Columns>
        <Separator />
        <EndContainer>
          <Mind />
          <Link target="_blank" href={CONDITIONS_LINK}>Conditions générales d'utilisation</Link> / <Link target="_blank" href={PRIVACY_LINK}>Confidentialité</Link>
          &nbsp;@ Mind 2022
        </EndContainer>
      </Content>
      <EndContainerMobile>
        <Mind />
        <EndContainerMiddle>
          <ColumnTitle>Téléchargez</ColumnTitle>
          <EndContainerDownload>
            {ios}
            {android}
          </EndContainerDownload>
          <EndContainerText>
            <Link target="_blank" href={CONDITIONS_LINK}>CGU</Link> / <Link target="_blank" href={PRIVACY_LINK}>Confidentialité</Link>
            &nbsp;@ Mind 2022
          </EndContainerText>
        </EndContainerMiddle>
        <div />
      </EndContainerMobile>
    </Container>
  )
}


export default Footer

import React, { PureComponent } from 'react'
import Styled from 'styled-components'
import classNames from 'classnames'

import { scrollTop } from '../helper'

import theme from '../theme'
import ArrowUpSrc from '../assets/Arrow-Up.png'


const Container = Styled.button`
  opacity: 0;
  z-index: 10;
  width: 46px;
  right: 50px;
  height: 46px;
  bottom: 50px;
  cursor: pointer;
  position: fixed;
  visibility: hidden;
  background-size: cover;
  background-position: center;
  transition: visibility ${theme.transition}, opacity: ${theme.transition};

  &.active {
    opacity: 1;
    visibility: visible;
  }
`


class ArrowUp extends PureComponent {
  /* LIFECYCLE */

  state = {
    active: false,
  }

  /**
   * @componentDidMount
   */
  componentDidMount() {
    document.body.addEventListener('scroll', this.onScroll)
  }

  /**
   * @componentWillUnmount
   */
  componentWillUnmount() {
    document.body.removeEventListener('scroll', this.onScroll)
  }


  /* METHODS */

  onScroll = () => {
    const { active } = this.state
    const { scrollTop } = document.body

    if (!active && scrollTop > 100) {
      this.setState({ active: true })
    } else if (active && scrollTop < 100) {
      this.setState({ active: false })
    }
  }


  /* RENDER */

  /**
   * @render
   * @returns {JSX}
   */
  render() {
    const {
      className,
      ...props
    } = this.props
    const { active } = this.state

    return (
      <Container
        role="button"
        onClick={scrollTop}
        style={{ backgroundImage: `url(${ArrowUpSrc})` }}
        className={classNames(className, { active })}
        {...props}
      />
    )
  }
}


export default ArrowUp

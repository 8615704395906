import React, { useState } from 'react'
import Styled from 'styled-components'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { ButtonCream } from './Button'
import { GIFT_LINK } from '../constant'

import theme from '../theme'
import BurgerIcon from '../assets/icons/Burger.svg'
import MindIcon from '../assets/icons/Mind.svg'


const Container = Styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: 28px;
  padding-left: 45px;
  padding-right: 6px;
  padding-bottom: 33px;
  height: 105px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.color.white};
`

const NavList = Styled.ol`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const NavItem = Styled.li`
  cursor: pointer;
  margin-right: 35px;

  & > a {
    font-size: 18px;
    color: ${theme.color.black};
    transition: color ${theme.transition};

    & > * {
      font-size: 18px;
    }

    &:hover,
    &.active {
      color: ${theme.color.primary};
    }
  }
`

const Logo = Styled.img`
  width: 110px;
  height: 44px;
`

const Burger = Styled.button`
  transform: scale(0.5);

  @media (min-width: ${theme.breakpoint}px) {
    display: none;
  }
`

const Mind = Styled(MindIcon)`
  height: 70px;
`

const NavMobile = Styled.nav`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transition: visibility ${theme.transition}, opacity ${theme.transition};
  background-color: ${theme.color.white};

  & ${NavList} {
    display: block;
  }

  & ${NavItem} {
    font-size: 23px;
    padding: 5px;
    margin-right: 0;
    margin-top: 40px;
    text-align: center;
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }
`

const ButtonSubscribe = Styled(ButtonCream)`
  display: inline-block;
  margin-top: 50px;
  margin-bottom: 40px;
`

const ButtonContainer = Styled.div`
  text-align: center;
`


const Header = ({
  onOpenModalMeditation,
}) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const navList = (
    <NavList>
      <NavItem><a onClick={onOpenModalMeditation}>La méditation</a></NavItem>
      <NavItem><Link activeClassName="active" to="/experts-mind">Les experts Mind</Link></NavItem>
      <NavItem><Link activeClassName="active" to="/entreprise">En entreprise</Link></NavItem>
      <NavItem><Link activeClassName="active" to="/workwise"><strong>WORK</strong>WISE</Link></NavItem>
      <NavItem><a href={GIFT_LINK} target="_blank">S'abonner</a></NavItem>
    </NavList>
  )

  return (
    <Container>
      <Link to="/"><Mind /></Link>

      <nav>
        {navList}
        <Burger onClick={toggleOpen}><BurgerIcon /></Burger>
      </nav>

      <NavMobile className={classNames({ open })}>
        {navList}

        <ButtonContainer>
          <ButtonSubscribe><a href={GIFT_LINK} target="_blank">S'abonner</a></ButtonSubscribe>
        </ButtonContainer>
      </NavMobile>
    </Container>
  )
}


export default Header

import Styled from 'styled-components'

import theme from '../theme'

const ButtonSecondary = Styled.div`
  display: inline-block;
  font-size: 16px;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-family: ${theme.typography.circularBook};

  color: ${theme.color.white};
  border-radius: ${theme.radius.big}px;
  background-color: ${theme.color.secondary};

  @media (max-width: ${theme.breakpoint}px) {
    height: auto;
    font-size: 14px;
    min-height: 52px;
  }

  & > * {
    display: block;
    line-height: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }
`

const ButtonWhite = Styled(ButtonSecondary)`
  background-color: ${theme.color.white};
  color: ${theme.color.alternativeBlack};
`

const ButtonBlue = Styled(ButtonSecondary)`
  background-color: ${theme.color.blue};
  color: ${theme.color.alternativeWhite};
`

const ButtonDarkBlue = Styled(ButtonSecondary)`
  background-color: ${theme.color.darkBlue};
  color: ${theme.color.alternativeWhite};
`

const ButtonDark = Styled(ButtonSecondary)`
  background-color: ${theme.color.dark};
  color: ${theme.color.alternativeWhite};
`

const ButtonCream = Styled(ButtonSecondary)`
  background-color: ${theme.color.semiWhite};
  color: ${theme.color.alternativeBlack};
`

const ButtonGreen = Styled(ButtonSecondary)`
  background-color: ${theme.color.enterprise.green};
  color: ${theme.color.white};
  font-size: 15px;
  line-height: 65px;
  letter-spacing: 0.15em;
  width: 300px;
  text-align: center;
`

export {
  ButtonDark,
  ButtonBlue,
  ButtonWhite,
  ButtonCream,
  ButtonGreen,
  ButtonDarkBlue,
  ButtonSecondary,
}
